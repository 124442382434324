export const locale = {
    lang: 'ru',
    data: {
        CONNECT_WALLET: {
            HEADER_TITLE_LOGIN: "Finance ManagementRu",
            HEADER_TITLE: "Finance ManagementRu",
            BUTTON_BACK:"Back",
            BODY_TITLE:"Inner Balance",
            BODY_DISCRIPTION:"In order to continue, you need to additionally log in through reconnecting the wallet.",
       
            BODY_BUTTON_RECONECT:"Connect wallet",
            TITLE: 'Wallet',
            WALLET_LIST: 'The list of available wallets',
            CLOSE: 'Close'
          }
    }
    
}