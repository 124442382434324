import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Web3Provider } from "@ethersproject/providers";

import { HelpInfo, walletInfo } from "app/auth/helpers";
import { environment } from "environments/environment";
import { ForceDeltaClient } from "localModules/metaforcesdk";
import { MetaPaymentClient } from "localModules/metapaymentsdk";
import { BehaviorSubject } from "rxjs";
declare let window: any;

@Injectable({
  providedIn: "root",
})
export class WalletService {
  public isBaksSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  provider: Web3Provider;
  currentProvider: any;
  private ForceDeltaClient: ForceDeltaClient;
  private MetaPaymentClient: MetaPaymentClient;

  isDisconnect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private helpInfo: HelpInfo,
    private http: HttpClient
  ) {}

  isConnect(): boolean {
    let result = this.helpInfo.userInfo.value;
    return result ? true : false;
  }

  disconect() {
    localStorage.setItem("walletConnect", JSON.stringify(false));
    this.helpInfo.walletInfo.next(undefined);
    this.helpInfo.userInfo.next(null);
    this.isDisconnect.next(true);
    this.ForceDeltaClient = undefined;
    this.MetaPaymentClient = undefined;
    this.helpInfo.walletAddress.next(null);
  }

  getBalancesOnPayment(userId?: number) {
    return this.ForceDeltaClient.getBalancesOnPayment(userId);
  }
  getBalancesOnPaymentWithOutId() {
    return this.ForceDeltaClient.getBalancesOnPayment();
  }
  getBalance() {
    return this.ForceDeltaClient.getBalancesOnWallet();
  }

  getUserId() {
    return this.ForceDeltaClient.getUserID();
  }

  getUserInfo(userId) {
    return this.ForceDeltaClient.getUserInfo(userId);
  }

  getAddressesTokens() {
    this.ForceDeltaClient.getAddressesTokens().then((x) => {
      this.helpInfo.tokenAddress.next(x);
    });
  }

  addToPayment(address: string, amount: number) {
    return this.MetaPaymentClient.add(address, amount + "");
  }

  getBalanceOtherToken(token: string) {
    return this.MetaPaymentClient.getBalance(token);
  }

  claimAll(token) {
    return this.MetaPaymentClient.claimAll(token);
  }

  claim(token, ammount: string) {
    return this.MetaPaymentClient.claim(token, ammount);
  }

  checkSignature(): Promise<boolean> {
    return this.ForceDeltaClient.checkSignature();
  }

  getReservedId(masteId: string) {
    return this.MetaPaymentClient.getReservedId(masteId);
  }
  getReservedAddress(userId: number) {
    return this.MetaPaymentClient.getReservedAddress(userId);
  }

  getMyInfo(wallet: string): any {
    return this.http.get("https://web3up.net/api/get-user/" + wallet);
  }

  async getSignature(provider): Promise<boolean> {
    console.log("getSignature");
    this.provider ??= provider;
    var res: boolean = false;
    try {
      if (JSON.parse(localStorage.getItem("walletConnect")) !== true) {
        let result = await this.checkSignature();
        if (!result) {
          // this.myWeb3ModalService.disconect()
          this.disconect();
          alert("Address not confirmed");
          return;
        } else {
          localStorage.setItem("walletConnect", JSON.stringify(true));
          res = true;
        }
      }
    } catch {
      this.disconect();
      localStorage.setItem("walletConnect", JSON.stringify(false));
    }
    return res;
  }

  callbackFn(accept: boolean) {
    // if (accept && this.helpInfo.expectainModalStep.value === 2) {
    //     this.helpInfo.expectainModalStep.next(
    //         this.helpInfo.expectainModalStep.value + 1
    //     );
    // }
    // if (!accept) {
    //     this.helpInfo.expectainModalStep.next(1);
    //     this.helpInfo.expectainModalIsOpen.next(false);
    // }
  }
  async getAccountInfo(provider): Promise<void> {
    console.log("getAccountInfo");
    this.provider = provider;
    this.getSignature(this.provider);
    const { chainId } = await this.provider.getNetwork();

    this.ForceDeltaClient = new ForceDeltaClient(
      this.provider.getSigner(),
      this.callbackFn
    );
    this.MetaPaymentClient = new MetaPaymentClient(this.provider.getSigner());

    let nameWallet = this.provider.connection.url.toUpperCase();
    this.provider.listAccounts().then((x) => {
      if (x.length > 0) {
        this.helpInfo.walletAddress.next(x[0]);
      } else {
        this.isDisconnect.next(true);
        this.helpInfo.walletAddress.next(null);
        this.helpInfo.userInfo.next(null);
        this.helpInfo.walletInfo.next(undefined);
        this.helpInfo.isConnect.next(false);
      }
    });

    let walletInfo: walletInfo = {} as walletInfo;
    walletInfo.signer = this.provider.getSigner();
    walletInfo.isConnect = true;
    this.helpInfo.isConnect.next(true);
    this.getAddressesTokens();
    this.provider.getNetwork().then((x) => {
      walletInfo.chainId = x.chainId;
      this.helpInfo.walletInfo.next(walletInfo);

      // Promise.all([ this.ForceDeltaClient.getUserInfo()]).then( x => {

      //   this.helpInfo.userInfo.next(x[0])
      // }, err=> {

      // })
    });
  }
}
