export const locale =
{
  lang: "en",
  data: {
    WALLET: {
      TITLE_DESCRIPTION:" On this page you can manage your Meta Force balance. The<br/> coin input and output function is available to you.",
      TITLE_WARNING:"You have signed in with the main wallet address. To manage <br/> your balance, please sign in with the trusted wallet address.",
      WALLET: "Wallet",
      EXTRA_WALLET: "Extra Wallet",
      BALANCE:"Inner Balance",
      DISCONECT:"Disconect",
      CANCEL:"Cancel",
      REPLENISH:"Deposit",
      SEND_TO_WALLET:"Send to wallet",
      MAIN_WALLET_ADDRESS:"Main wallet address:",
      TRUSTED_WALLET_ADDRESS:"Extra Wallet address:",
      FORCID:"ForcID",
      WARNING:'Should you experience any issues or have any questions,<br/> don\'t hesitate to reach out to our technical support team.',
    }
  }
}