export const locale = {
    lang: 'ru',
    data: {
        WALLET:{ 
            TITLE_DESCRIPTION:"In order to continue, you need to additionally log in through<br/> reconnecting the wallet. <br/>On this page you can manage your Meta Force balance. The<br/> coin input and output function is available to you.",
            WALLET: "Wallet",
            EXTRA_WALLET: "Extra Wallet",
            BALANCE:"Balance",
            DISCONECT:"Disconect",
            CANCEL:"Cancel",
            REPLENISH:"Deposit",
            SEND_TO_WALLET:"Send to wallet",
            WARNING:'The service is only available to users who are seeking help<br/> from technical support.',
        }
    }
}