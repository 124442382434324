import { Core } from './contracts/types/Core';
import { Energy } from './contracts/types/Energy';
import { Erc20 } from './contracts/types/Erc20';
import { Forceswap } from "./contracts/types/Forceswap";
import { Holding } from "./contracts/types/Holding";
import { MetaCore } from "./contracts/types/MetaCore";
import { MetaForce } from "./contracts/types/MetaForce";
import { MetaPayment } from "./contracts/types/MetaPayment";
import { Mfs } from "./contracts/types/Mfs";
import { Registry } from "./contracts/types/Registry";
import { Request } from "./contracts/types/Request";
export enum RewardType {
  Mfs,
  MfsStablecoin,
  Stablecoin,
}

export enum StatusRequest {
  Pending,
  Approved,
  Declined,
}

export enum WorkflowStage {
  Preparatory,
  Presale,
  SaleHold,
  SaleOpen,
}

export enum RenewalCurrency {
  Mfs,
  hMfs1,
  hMfs2,
  hMfs3,
  hMfs4,
  hMfs5,
  hMfs6,
  hMfs7,
  hMfs8,
}

export type Pack = {
  timeStampEndPack: Date;
  isActive: boolean;
};

export type ForceCoinPrice = {
  changesDate: Date;
  price: string;
};

export type MarketingUserForBinary = {
  id: number;
  marketingReferrer: number;
  tier: number;
};

export type MarketingUserForLevel = {
  id: number;
  refLevel: number;
};

export type ReferralUserInfo = {
  userId: number;
  avatar: string;
  alias: string;
  referrerId: number;
  referralsAmount: number;
};

export type UserInfo = {
  address: string;
  rewardType: RewardType;
  level: number;
  registeredAt: Date;
  nickName: string;
};

export type Deposit = {
  holderId: number;
  unholdingAllowed: boolean;
  entryLevel: number;
  amount: string;
  createdAt: Date;
};

export type Contracts = {
  registry: Registry;
  metaCore: MetaCore;
  metaPayment: MetaPayment;
  core: Core;
  holding: Holding;
  metaForce: MetaForce;
  request: Request;
  mfs: Mfs;
  stablecoin: Mfs;
  energy: Energy;
  hMfs: Erc20[];
  forceswap: Forceswap;
};

export type Tokens = {
  mfs: string;
  stablecoin: string;
  energy: string;
  hMfs: string[];
};

export type EnoughMatic = {
  enough: boolean;
  maticBalance: string;
};

export type Stats = {
  workflowStage: WorkflowStage;
  firstPackPriceInMFS: string;
  mfsPriceInUSD: string;
  mfsTotalEmission: string;
  bigBlockNumber: number;
  endBigBlockEmission: string;
  smallBlockNumber: number;
  endSmallBlockEmission: string;
  usersCount: string;
  totalRevenue: string;
};

export type Balances = {
  mfs: string;
  stablecoin: string;
  energy: string;
  hMfs: string[];
};

export type MfsRequest = {
  requester: string;
  startUSDAmount: string;
  usdAmount: string;
  buyedMFS: string;
  spentUSD: string;
  priority: number;
  status: StatusRequest;
  placeInQueue: number;
};

export type PossibilityBuyingMFS = {
  endBuyingPeriodDate: Date;
  amount: string;
};

export type Event = {
  contract: string;
  userId: number;
  name: string;
  date: Date;
  value: string;
  amount: string;
};

export type RequestedMFS = {
  total: string;
  airdrop: string;
  self: string;
};

export type PageInfo = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

export type NftChipResponse = {
  items: NftChip[];
  pageInfo: PageInfo;
  totalCount: number;
};

export type NftChip = {
  tokenId: string;
  contractAddress: string;
  nftMetadata: {
    name: string;
    image: string;
    // description: string;
  };
  nftContract: {
    name: string;
    level: string;
  };
};

export type Web3ForceSwapOrder = {
  orderId: string;
  amount: string;
  queueNumber: string;
};

export type ForceSwapOrderChange = {
  id: string;
  orderId: string;
  createdAt: Date;
  txHash: string;
  type: string;
  amount: string;
  amountInUsd: string;
  chain: {
    blockExplorerUrl: string;
  };
};

export type ForceSwapOrderChangesResponse = {
  items: ForceSwapOrderChange[];
  pageInfo: PageInfo;
  totalCount: number;
};
