import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule],
  exports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule]
})
export class CoreCommonModule {}
