/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type { MetaForce, MetaForceInterface } from "../MetaForce";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "a",
        type: "uint256",
      },
    ],
    name: "FixedPointMathExp2ArgumentTooBig",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "a",
        type: "uint256",
      },
    ],
    name: "FixedPointMathLog2ArgumentTooBig",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "prod1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "denominator",
        type: "uint256",
      },
    ],
    name: "FixedPointMathMulDivOverflow",
    type: "error",
  },
  {
    inputs: [],
    name: "GovernedCantGoverItself",
    type: "error",
  },
  {
    inputs: [],
    name: "GovernedGovernorZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "GovernedOnlyGovernorAllowedToCall",
    type: "error",
  },
  {
    inputs: [],
    name: "GovernedOnlyPendingGovernorAllowedToCall",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCActivationAmountIs0",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCAmountsIsNotChange",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCAmountsLengthNotEqualToTierLevelCount",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCEarlyStageForActivatePack",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCEarlyStageForRenewalPackInHMFS",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCLevelMoreMaxPackLevel",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
    ],
    name: "MFCNeedActivatePack",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCNotEnoughHMFSNeedLevel",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCNotFirstActivationPack",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCPackLevelIs0",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCRenewalAmountIs0",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCRenewalPaymentIsOnlyPossibleInHMFS",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCRewardsIsNotChange",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCSizeArrayDifferentFromExpected",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCSumRewardsMustBeHundred",
    type: "error",
  },
  {
    inputs: [],
    name: "MFCUserIsNotRegistredInMarketing",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "governor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newGovernor",
        type: "address",
      },
    ],
    name: "GovernanceTransited",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "accountId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "fromId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "marketing",
        type: "bool",
      },
    ],
    name: "LostMoney",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "MFCAmountChipEnergyForLevelIsSetted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "user",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timestampEndPack",
        type: "uint256",
      },
    ],
    name: "MFCPackIsActivated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "user",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timestampEndPack",
        type: "uint256",
      },
    ],
    name: "MFCPackIsRenewed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "registry",
        type: "address",
      },
    ],
    name: "MFCRegistryContractAddressSetted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "MFCRewardsChipEnergeForMarketingIsSetted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "user",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "MFCTokensMFSIsBuyingInOffer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "MFSPenaltyFactorForRenewalIsSetted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "governor",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newGovernor",
        type: "address",
      },
    ],
    name: "PendingGovernanceTransition",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "marketingRefererId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "userId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "mRewardChipEnergy",
        type: "uint256",
      },
    ],
    name: "RevenueChipEnergy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "accountId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "fromId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "activate",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "marketing",
        type: "bool",
      },
    ],
    name: "RevenueMFS",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "accountId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "fromId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "activate",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "marketing",
        type: "bool",
      },
    ],
    name: "RevenueStable",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptGovernance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "forMFS",
        type: "bool",
      },
    ],
    name: "activationPack",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "amountChipEnergyForLevel",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "amountForSwapSFCR",
    outputs: [
      {
        internalType: "uint256",
        name: "amountForSwapSFCR",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "swapedSFCR",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "swapedSFCR2",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "senderId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "calcNeedMFSForRenew",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "prices",
        type: "uint256[2]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "countRenewal",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "datesForBuying",
    outputs: [
      {
        internalType: "uint256",
        name: "date",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "estimatedMarketinReferrerId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "forMFS",
        type: "bool",
      },
    ],
    name: "firstActivationPack",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "replaceId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "forMFS",
        type: "bool",
      },
    ],
    name: "firstActivationPackWithReplace",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "governor",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IRegistryContract",
        name: "_registry",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "lastIndexBuying",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "penaltiyFactorForRenewal",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingGovernor",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "permissionSubsequentPurchase",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "amountsCurrency",
        type: "uint256[]",
      },
    ],
    name: "renewalPack",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "level",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "withoutConversionStable",
        type: "bool",
      },
    ],
    name: "renewalPackForMFS",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "rewardsChipEnergyForMarketing",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_amountChipEnergyForLevel",
        type: "uint256[]",
      },
    ],
    name: "setAmountChipEnergyForLevel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_penalty",
        type: "uint256",
      },
    ],
    name: "setPenaltyForRenewal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_rewardsChipEnergyForMarketing",
        type: "uint256[]",
      },
    ],
    name: "setRewardsChipEnergyForMarketing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newGovernor",
        type: "address",
      },
      {
        internalType: "bool",
        name: "force",
        type: "bool",
      },
    ],
    name: "transitGovernance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class MetaForce__factory {
  static readonly abi = _abi;
  static createInterface(): MetaForceInterface {
    return new utils.Interface(_abi) as MetaForceInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MetaForce {
    return new Contract(address, _abi, signerOrProvider) as MetaForce;
  }
}
