import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoreCommonModule } from '@core/common.module';

import { RouterModule } from '@angular/router';
import { ConnectWalletComponent } from './connect-wallet/connect-wallet.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavbarWalletsComponent } from './navbar-wallets/navbar-wallets.component';


const routes = [


];

@NgModule({
  declarations: [
    ConnectWalletComponent,
    NavbarWalletsComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    TranslateModule ,
    RouterModule.forChild(routes)
  ],

  providers: [],
  exports:[ConnectWalletComponent,NavbarWalletsComponent]
})
export class PagesModule {}
