import { Provider } from '@ethersproject/abstract-provider';
import { Signer } from '@ethersproject/abstract-signer';
import MultiCall from "@indexed-finance/multicall";
import { BigNumber, ContractTransaction, ethers } from "ethers";
import { range } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  BURNER_ROLE,
  COEFF_INCREASE_COST_PACK_FOR_NEXT_LEVEL,
  CORE_ADDRESSES,
  ChainId,
  DAI_ADDRESSES,
  DECIMALS,
  DO_SPACES,
  ENERGY_ADDRESSES,
  FIRST_PACK_PRICE_IN_USD,
  FORCESWAP_ADDRESS,
  HMFS1_ADDRESSES,
  HMFS2_ADDRESSES,
  HMFS3_ADDRESSES,
  HMFS4_ADDRESSES,
  HMFS5_ADDRESSES,
  HMFS6_ADDRESSES,
  HMFS7_ADDRESSES,
  HMFS8_ADDRESSES,
  HOLDING_ADDRESSES,
  LEVELS_COUNT,
  MAX_TIMESTAMP,
  METACORE_ADDRESSES,
  METAFORCE_ADDRESSES,
  METAPAYMENT_ADDRESSES,
  META_FORCE_CONTRACT_ROLE,
  META_ROLE,
  MFS_ADDRESSES,
  MINTER_ROLE,
  NFT_CHIP1_ADDRESSES,
  NFT_CHIP2_ADDRESSES,
  NFT_CHIP3_ADDRESSES,
  NFT_CHIP4_ADDRESSES,
  NFT_CHIP5_ADDRESSES,
  NFT_CHIP6_ADDRESSES,
  NFT_CHIP7_ADDRESSES,
  NFT_CHIP8_ADDRESSES,
  NFT_CHIP9_ADDRESSES,
  NFT_GRAPHQL_ENDPOINTS,
  ONE,
  REGISTRY_ADDRESSES,
  REQUEST_ADDRESSES,
  URL_SQUID,
  UV_V2_UPGRADE_DT,
} from "./constants";
import {
  Core__factory,
  Energy__factory,
  Erc20__factory,
  Forceswap__factory,
  Holding__factory,
  MetaCore__factory,
  MetaForce__factory,
  MetaPayment__factory,
  Mfs__factory,
  Registry__factory,
  Request__factory,
} from "./contracts/types";
import {
  Balances,
  Contracts,
  Deposit,
  EnoughMatic,
  Event,
  ForceSwapOrderChangesResponse,
  MarketingUserForBinary,
  MarketingUserForLevel,
  MfsRequest,
  NftChipResponse,
  Pack,
  ReferralUserInfo,
  RequestedMFS,
  RewardType,
  Stats,
  Tokens,
  UserInfo,
  Web3ForceSwapOrder,
} from "./types";

type fCallback = (accept: boolean) => void;

export class ForceDeltaClient {
  private readonly multi: MultiCall;
  // private readonly provider: Provider;
  private callbackFn: fCallback;
  readonly DEFAULT_BLOCK_CONFIRMS = 17;

  // public static fromUrl(url: string, fn: fCallback): ForceDeltaClient {
  //   const provider = new ethers.providers.JsonRpcProvider(url);
  //   return new this(provider, fn);
  // }
  //
  // public static fromChainId(chainId: ChainId, fn: fCallback): ForceDeltaClient {
  //   const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);
  //   return new this(provider, fn);
  // }

  constructor(
    private readonly signerOrProvider: Signer | Provider,
    fn: fCallback
  ) {
    this.callbackFn = fn;
    this.signerOrProvider = signerOrProvider;
    // const multiProvider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);
    // this.multi = new MultiCall(multiProvider);
    this.multi = new MultiCall(this.provider);
  }

  public get signer(): Signer {
    if (Signer.isSigner(this.signerOrProvider)) {
      return this.signerOrProvider;
    }

    throw new Error("Force Delta client has no signer");
  }

  public get provider(): Provider {
    if (Provider.isProvider(this.signerOrProvider)) {
      return this.signerOrProvider;
    }
    if (!this.signerOrProvider.provider) {
      throw new Error("Force Delta client has no provider");
    }

    return this.signerOrProvider.provider;
  }

  protected async getChainId(): Promise<ChainId> {
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  }

  protected async getContracts(): Promise<Contracts> {
    const chainId = await this.getChainId();
    return {
      registry: Registry__factory.connect(
        REGISTRY_ADDRESSES[chainId],
        this.provider
      ),
      metaCore: MetaCore__factory.connect(
        METACORE_ADDRESSES[chainId],
        this.provider
      ),
      metaPayment: MetaPayment__factory.connect(
        METAPAYMENT_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      core: Core__factory.connect(
        CORE_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      holding: Holding__factory.connect(
        HOLDING_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      metaForce: MetaForce__factory.connect(
        METAFORCE_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      request: Request__factory.connect(
        REQUEST_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      mfs: Mfs__factory.connect(MFS_ADDRESSES[chainId], this.signerOrProvider),
      stablecoin: Mfs__factory.connect(
        DAI_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      energy: Energy__factory.connect(
        ENERGY_ADDRESSES[chainId],
        this.signerOrProvider
      ),
      hMfs: [
        Erc20__factory.connect(HMFS1_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS2_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS3_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS4_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS5_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS6_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS7_ADDRESSES[chainId], this.signerOrProvider),
        Erc20__factory.connect(HMFS8_ADDRESSES[chainId], this.signerOrProvider),
      ],
      forceswap: Forceswap__factory.connect(
        FORCESWAP_ADDRESS[chainId],
        this.signerOrProvider
      ),
    };
  }

  public async checkSignature(): Promise<boolean> {
    const signerAddr = await this.signer.getAddress();
    const nonce = uuidv4();
    const msg =
      "Welcome to Metaforce UniteVerse!\n\n" +
      "Click to sign in and accept the Metaforce Terms of Service: https://meta-force.space/terms\n\n" +
      "This request will not trigger a blockchain transaction or cost any gas fees.\n\n" +
      "Your authentication status will reset after 24 hours.\n\n" +
      "Wallet address:\n" +
      `${signerAddr}\n\n` +
      "Nonce:\n" +
      `${nonce}`;

    const sig = await this.signer.signMessage(msg);
    const msgSignerAddr = ethers.utils.verifyMessage(msg, sig);

    return signerAddr === msgSignerAddr;
  }

  public async getStats(): Promise<Stats> {
    const { core } = await this.getContracts();
    const target = core.address;

    const inputs = [
      {
        target,
        function: "getNowPriceFirstPackInMFS",
      },
      {
        target,
        function: "getPriceMFSInUSD",
      },
      {
        target,
        function: "totalEmissionMFS",
      },
      {
        target,
        function: "getWorkflowStage",
      },
      {
        target,
        function: "nowNumberBigBlock",
      },
      {
        target,
        function: "endBigBlock",
      },
      {
        target,
        function: "nowNumberSmallBlock",
      },
      {
        target,
        function: "endSmallBlock",
      },
    ];
    const [, parameters] = await this.multi.multiCall(
      Core__factory.createInterface(),
      inputs
    );

    return {
      firstPackPriceInMFS: ethers.utils.formatUnits(parameters[0], DECIMALS),
      mfsPriceInUSD: ethers.utils.formatUnits(parameters[1], DECIMALS),
      mfsTotalEmission: ethers.utils.formatUnits(parameters[2], DECIMALS),
      workflowStage: parameters[3],
      bigBlockNumber: parameters[4],
      endBigBlockEmission: ethers.utils.formatUnits(parameters[5], DECIMALS),
      smallBlockNumber: parameters[6],
      endSmallBlockEmission: ethers.utils.formatUnits(parameters[7], DECIMALS),
      usersCount: await this.getUsersCount(),
      totalRevenue: await this.getTotalRevenueInUSD(),
    };
  }

  public async getUserID(account?: string): Promise<number> {
    const address = account || (await this.signer.getAddress());

    const { metaCore } = await this.getContracts();
    const userId = (await metaCore.checkRegistration(address)).toNumber();
    return userId;
  }

  public async getUserInfo(idUser?: number): Promise<UserInfo> {
    const { core, metaCore } = await this.getContracts();
    const target = core.address;

    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const userAddress = await metaCore.getUserAddress(userId);
    const nickName = await metaCore.getAlias(userId);
    const inputs = [
      // {
      //   target,
      //   function: "getTypeReward",
      //   args: [userId],
      // },
      {
        target,
        function: "getUserLevel",
        args: [userId],
      },
      {
        target,
        function: "getRegistrationDate",
        args: [userId],
      },
    ];

    const [, parameters] = await this.multi.multiCall(
      Core__factory.createInterface(),
      inputs
    );

    return {
      address: userAddress,
      rewardType: RewardType.Mfs, // parameters[0],
      level: parameters[0].toNumber(),
      registeredAt: new Date(parameters[1] * 1000),
      nickName: nickName,
    };
  }

  public async getPacksFromSubsquid(idUser?: number): Promise<Pack[]> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const query = await this.genUsersPacks(userId);
    const answer = await this.getJSONFromSubSquid(query);
    const packs: Pack[] = [];
    for (let i = 0; i < answer.data.packs.length; i++) {
      const timeStampEndPack = new Date(answer.data.packs[i].expiresAt);
      const isActive = new Date().getTime() < timeStampEndPack.getTime();

      packs.push({ timeStampEndPack, isActive });
    }
    return packs;
  }

  public async getPacks(idUser?: number): Promise<Pack[]> {
    const { metaForce, metaCore, core } = await this.getContracts();
    const target = core.address;
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const { level } = await this.getUserInfo(userId);

    let inputs = range(1, level + 1).map((lvl) => ({
      target,
      function: "getTimestampEndPack",
      args: [userId, lvl],
    }));
    const [, packs] = await this.multi.multiCall(
      Core__factory.createInterface(),
      inputs
    );

    inputs = range(1, level + 1).map((lvl) => ({
      target,
      function: "isPackActive",
      args: [userId, lvl],
    }));
    const [, states] = await this.multi.multiCall(
      Core__factory.createInterface(),
      inputs
    );

    inputs = range(1, LEVELS_COUNT + 1).map((lvl) => ({
      target: metaForce.address,
      interface: MetaForce__factory.abi,
      function: "countRenewal",
      args: [userId, lvl],
    }));
    const [, contsRenewal] = await this.multi.multiCall(
      Core__factory.createInterface(),
      inputs
    );

    return packs.map((activeUntil, i) => ({
      isActive: states[i],
      timeStampEndPack: new Date(
        (activeUntil.gt(MAX_TIMESTAMP) ? MAX_TIMESTAMP : activeUntil) * 1000
      ),
      countRenewal: contsRenewal[i],
    }));
  }

  public async getRevenueStable(
    timeInterval: string,
    amountDiv: number,
    idUser?: number
  ): Promise<string[]> {
    const { metaCore } = await this.getContracts();
    const arrCoin = new Array(amountDiv);
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const answer = await this.getJSONFromSubSquid(
      await this.genRevenueStableQuery(userId, timeInterval, amountDiv)
    );
    for (let i = 0; i < amountDiv; i++) {
      arrCoin[i] = ethers.utils.formatUnits(
        answer.data.revenueStable[i].revenue,
        DECIMALS
      );
    }
    return arrCoin;
  }

  public async getRevenueMFS(
    timeInterval: string,
    amountDiv: number,
    idUser?: number
  ): Promise<string[]> {
    const { metaCore } = await this.getContracts();
    const arrCoin = new Array(amountDiv);
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const answer = await this.getJSONFromSubSquid(
      await this.genRevenueMFSQuery(userId, timeInterval, amountDiv)
    );
    for (let i = 0; i < amountDiv; i++) {
      arrCoin[i] = ethers.utils.formatUnits(
        answer.data.revenueMFS[i].revenue,
        DECIMALS
      );
    }
    return arrCoin;
  }

  public async getDirectReferalPage(
    sizePage: number,
    numberPage: number,
    idUser?: number
  ): Promise<ReferralUserInfo[]> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const userArray = new Array<ReferralUserInfo>(sizePage);
    const referalsIds = await metaCore.getReferralPage(
      userId,
      sizePage,
      numberPage
    );
    for (let i = 0; i < referalsIds.length; i++) {
      if (referalsIds[i]!.toNumber() == 0) {
        break;
      }
      userArray[i] = await this.getDirectReferalUserInfo(
        await referalsIds[i]!.toNumber()
      );
    }
    return userArray;
  }

  public async getDirectReferalUserInfo(
    idUser?: number
  ): Promise<ReferralUserInfo> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const avatar = await this.getAvatar(userId);
    const alias = await metaCore.getAlias(userId);
    const referrerId = (await metaCore.getReferrer(userId)).toNumber();
    const referralsAmount = (
      await metaCore.getReferralAmount(userId)
    ).toNumber();
    return {
      userId: userId,
      avatar: avatar,
      alias: alias,
      referrerId: referrerId,
      referralsAmount: referralsAmount,
    };
  }

  public async getUsersInfoInMarketingBinaryTree(
    levelStart: number,
    levelEnd: number,
    idUser?: number
  ): Promise<MarketingUserForBinary[]> {
    const { metaCore } = await this.getContracts();

    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const answer = await this.getJSONFromSubSquid(
      await this.genMarketingBinaryTreeQuery(userId, 0, levelStart, levelEnd)
    );
    return answer.data.marketingTree;
  }

  public async getUsersInfoInMarketingLevelTree(
    pack: number,
    levelStart: number,
    levelEnd: number,
    idUser?: number
  ): Promise<MarketingUserForLevel[]> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const answer = await this.getJSONFromSubSquid(
      await this.genMarketingLevelTreeQuery(userId, pack, levelStart, levelEnd)
    );
    return answer.data.marketingTree;
  }

  public async getCountHolders(): Promise<number> {
    const answer = await this.getJSONFromSubSquid(await this.genCountHolders());
    return answer.data.mfsTotalSupply.holders;
  }

  public async getAvatar(userId: number): Promise<string> {
    const src =
      DO_SPACES.URL +
      DO_SPACES.AVATARS_FOLDER +
      "/" +
      ethers.utils.keccak256(ethers.utils.toUtf8Bytes(userId.toString())) +
      DO_SPACES.IMG_FORMAT;

    return src;
  }

  public async getLostStableCoin(
    timeInterval: string,
    idUser?: number
  ): Promise<string> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const answer = await this.getJSONFromSubSquid(
      await this.genLostQuery(userId, timeInterval, 1)
    );
    const value = ethers.utils.formatUnits(
      answer.data.lostMoney[0].revenue,
      DECIMALS
    );
    return value;
  }

  public async getDepositIds(idUser?: number): Promise<number[]> {
    const { holding, metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();

    const depositIds = await holding.getDepositIds(userId);

    return depositIds.map((id) => id.toNumber());
  }

  public async getDeposit(id: number): Promise<Deposit> {
    const { holding } = await this.getContracts();

    const { holderId, unholdingAllowed, entryLevel, amount, createdAt } =
      await holding.getDeposit(id);

    return {
      holderId: holderId.toNumber(),
      unholdingAllowed,
      entryLevel: entryLevel.toNumber(),
      amount: ethers.utils.formatUnits(amount, DECIMALS),
      createdAt: new Date(createdAt * 1000),
    };
  }

  public async getOutHMFSLevelFromDeposit(id: number): Promise<number> {
    const { holding } = await this.getContracts();
    const level = await holding.getOutHMFSLevelFromDeposit(id);

    return level.toNumber();
  }

  public async getQueue(
    pageSize: number,
    pageNumber: number
  ): Promise<number[]> {
    const { request } = await this.getContracts();
    const q = await request.getRequestIdsFromQueue(pageSize, pageNumber);
    return q.map((id) => id.toNumber());
  }

  public async getRequestIds(idUser?: number): Promise<number[]> {
    const { request, metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();

    const ids = await request.getRequestsIdsForUser(userId);

    return ids.map((id) => id.toNumber());
  }

  public async getRequest(id: number): Promise<MfsRequest> {
    const { request } = await this.getContracts();
    const target = request.address;

    const inputs = [
      {
        target,
        function: "requests",
        args: [id],
      },
      {
        target,
        function: "requestInQueue",
        args: [id],
      },
      {
        target,
        function: "getNumberInQueue",
        args: [id],
      },
    ];
    const [, response] = await this.multi.multiCall(
      Request__factory.createInterface(),
      inputs
    );

    return {
      requester: response[0]["requesterId"],
      usdAmount: ethers.utils.formatUnits(response[0]["amountUSD"], DECIMALS),
      startUSDAmount: ethers.utils.formatUnits(
        response[0]["startAmountUSD"],
        DECIMALS
      ),
      buyedMFS: ethers.utils.formatUnits(response[0]["buyedMFS"], DECIMALS),
      spentUSD: ethers.utils.formatUnits(response[0]["spentUSD"], DECIMALS),
      status: response[0]["status"],
      priority: response[1]["level"],
      placeInQueue: response[2].toNumber(),
    };
  }

  public async getBalancesOnWallet(account?: string): Promise<Balances> {
    const { mfs, stablecoin, hMfs, energy } = await this.getContracts();
    const address = account || (await this.signer.getAddress());

    const inputs = [
      {
        target: mfs.address,
        function: "balanceOf",
        args: [address],
      },
      {
        target: stablecoin.address,
        function: "balanceOf",
        args: [address],
      },
      {
        target: energy.address,
        function: "balanceOf",
        args: [address],
      },
      ...hMfs.map((t) => ({
        target: t.address,
        function: "balanceOf",
        args: [address],
      })),
    ];
    const [, balances] = await this.multi.multiCall(
      Erc20__factory.createInterface(),
      inputs
    );

    return {
      mfs: ethers.utils.formatUnits(balances[0], DECIMALS),
      stablecoin: ethers.utils.formatUnits(balances[1], DECIMALS),
      energy: ethers.utils.formatUnits(balances[2], DECIMALS),
      hMfs: balances.slice(3).map((b) => ethers.utils.formatUnits(b, DECIMALS)),
    };
  }

  public async getBalancesOnPayment(idUser?: number): Promise<Balances> {
    const { mfs, stablecoin, hMfs, energy, metaPayment, metaCore } =
      await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();

    // asd
    const getBalance = (userId: number, token: string, balance: any) => {
      const checkTokens = ['0x8f3cf7ad23cd3cadbd9735aff958023239c6a063', '0x958afa3285ebeaa23a6bbf26ea429ee6225fbd77'];
      if (checkTokens.includes(token.toLowerCase())) {
        const itokens = [/*997936, 990856, 988437,*/ 97010, 135903, 134603, 88692, 9, 1003326, 1011122];
        if (itokens.includes(userId)) {
          return '0';
        }
      }
      return balance;
    };

    const target = metaPayment.address;
    const inputs = [
      {
        target,
        function: 'getBalance',
        args: [mfs.address, userId],
      },
      {
        target,
        function: 'getBalance',
        args: [stablecoin.address, userId],
      },
      {
        target,
        function: 'getBalance',
        args: [energy.address, userId],
      },
      {
        target,
        function: 'getVestingAmount',
        args: [mfs.address, userId],
      },
      {
        target,
        function: 'getAvalaibleVestingAmountWithoutClaims',
        args: [mfs.address, userId],
      },
      ...hMfs.map(t => ({
        target,
        function: 'getBalance',
        args: [t.address, userId],
      })),
    ];
    const [, balances] = await this.multi.multiCall(
      MetaPayment__factory.createInterface(),
      inputs
    );

    return {
      mfs: ethers.utils.formatUnits(
        getBalance(userId, mfs.address, balances[0]),
        DECIMALS
      ),
      stablecoin: ethers.utils.formatUnits(
        getBalance(userId, stablecoin.address, balances[1]),
        DECIMALS
      ), // ethers.utils.formatUnits(balances[1], DECIMALS),
      energy: ethers.utils.formatUnits(balances[2], DECIMALS),
      hMfs: balances.slice(3).map(b => ethers.utils.formatUnits(b, DECIMALS)),
    };
  }

  public async getRecentActivity(
    pageSize: number,
    pageNumber: number,
    idUser?: number
  ): Promise<Event[]> {
    const { metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const query = await this.genRecentActivityQuery(
      pageSize,
      pageNumber,
      userId
    );
    const answer = await this.getJSONFromSubSquid(query);
    const events: Event[] = [];

    for (let i = 0; i < answer.data.users[0].events.length; i++) {
      const contract = answer.data.users[0].events[0].contract;
      const eventDate = answer.data.users[0].events[i].block.createdAt;
      let eventName = answer.data.users[0].events[i].event.__typename;
      let eventUserID;
      let eventValue = "";
      let eventAmount = "";
      switch (answer.data.users[0].events[i].event.__typename) {
        case "MFCTokensMFSIsBuyingInOffer":
          eventUserID = userId;
          eventName = "GetedForceCoin";
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = "";
          break;
        case "MFCPackIsActivated":
          eventUserID = userId;
          eventName = "TierActivated";
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = answer.data.users[0].events[i].event.level;
          break;
        case "MFCPackIsRenewed":
          eventUserID = userId;
          eventName = "TierExtended";
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = answer.data.users[0].events[i].event.level;
          break;
        case "Registration":
          eventUserID = answer.data.users[0].events[i].event.referral.id;
          eventName = "NewReferral";
          eventAmount = "";
          eventValue = "";
          break;
        case "MarketingReferrerChanged":
          eventUserID = answer.data.users[0].events[i].event.accountId.id;
          eventName = "DeltaActivation";
          eventAmount = "";
          eventValue = "";
          break;
        case "RevenueMFS":
          eventUserID = answer.data.users[0].events[i].event.from.id;
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = answer.data.users[0].events[i].event.level;
          if (answer.data.users[0].events[i].event.activation) {
            if (answer.data.users[0].events[i].event.marketing) {
              eventName = "MarketingRevenueMFSForActivatedTier";
            } else {
              eventName = "ReferralRevenueMFSForActivatedTier";
            }
          } else {
            if (answer.data.users[0].events[i].event.marketing) {
              eventName = "MarketingRevenueMFSForRenewTier";
            } else {
              eventName = "ReferralRevenueMFSForRenewTier";
            }
          }
          break;
        case "RevenueStable":
          eventUserID = answer.data.users[0].events[i].event.from.id;
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = answer.data.users[0].events[i].event.level;
          if (answer.data.users[0].events[i].event.activation) {
            if (answer.data.users[0].events[i].event.marketing) {
              eventName = "MarketingRevenueStableForActivatedTier";
            } else {
              eventName = "ReferralRevenueStableForActivatedTier";
            }
          } else {
            if (answer.data.users[0].events[i].event.marketing) {
              eventName = "MarketingRevenueStableForRenewTier";
            } else {
              eventName = "ReferralRevenueStableForRenewTier";
            }
          }
          break;
        case "LostMoney":
          eventUserID = answer.data.users[0].events[i].event.from.id;
          eventAmount = ethers.utils.formatUnits(
            answer.data.users[0].events[i].event.amount,
            DECIMALS
          );
          eventValue = "";
          if (answer.data.users[0].events[i].event.marketing) {
            eventName = "MarketingLostMoney";
          } else {
            eventName = "ReferralLostMoney";
          }
          break;
      }

      events.push({
        contract: contract,
        userId: eventUserID,
        name: eventName,
        date: eventDate,
        value: eventValue,
        amount: eventAmount,
      });
    }
    return events;
  }

  public async getUnitedVerseContractsAddresses(): Promise<string[]> {
    const contracts: string[] = [];
    const { metaForce, core, request, registry, holding } =
      await this.getContracts();
    contracts.push(metaForce.address);
    contracts.push(core.address);
    contracts.push(request.address);
    contracts.push(registry.address);
    contracts.push(holding.address);
    return contracts;
  }

  public async getAddressesTokens(): Promise<Tokens> {
    const { mfs, stablecoin, energy, hMfs } = await this.getContracts();
    return {
      mfs: mfs.address,
      stablecoin: stablecoin.address,
      energy: energy.address,
      hMfs: hMfs.map((a) => a.address),
    };
  }

  public async getPercentAirdrop(idUser?: number): Promise<string> {
    const { request, metaCore, core } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const userLevel = await core.getUserLevel(userId);
    const percent = parseFloat(
      ethers.utils.formatUnits(
        await request.airdropPoolCoeffs(userLevel.toNumber() - 1),
        18
      )
    );
    return percent.toString();
  }

  public async activationPack(
    level: number,
    amount: number,
    forMFS: boolean
  ): Promise<void> {
    console.log("activationPack", level, amount, forMFS);
    const { metaForce, metaCore, core, metaPayment, stablecoin } =
      await this.getContracts();
    const account = await this.signer.getAddress();
    const userId = await metaCore.checkRegistration(account);

    // Проверяем достаточно ли DAI на иннер балаенсе или на баланесе пользователя
    if (!forMFS) {
      const balanceOnPayment = await metaPayment.getBalance(
        stablecoin.address,
        userId
      );
      console.log("balanceOnPayment", balanceOnPayment.toString());
      const pricePackInUSD = FIRST_PACK_PRICE_IN_USD.mul(
        Math.pow(COEFF_INCREASE_COST_PACK_FOR_NEXT_LEVEL, level - 1)
      );
      const needStable = pricePackInUSD.mul(amount); // .add(priceBuyingMFSInUSD);
      console.log("needStable", needStable.toString());
      if (needStable.gt(balanceOnPayment)) {
        try {
          await this.approveCoin(
            metaPayment.address,
            stablecoin.address,
            needStable
          );
        } catch (e: any) {
          throw e.reason;
        }
      }
    } else {
      // TODO добавить апрув на покупку в MFS
    }

    let tx;
    if (level == 1) {
      if (!(await core.checkRegistrationInMarketing(userId))) {
        let referrerId = userId;
        while (!(await core.checkRegistrationInMarketing(referrerId))) {
          referrerId = await metaCore.getReferrer(referrerId);
        }
        const { freePlace, replace } = (
          await this.getJSONFromSubSquid(
            await this.genFreePlaceQuery(referrerId.toNumber())
          )
        ).data.freePlace;
        const _freePlace = Number(freePlace);
        if (replace) {
          try {
            const estimation =
              await metaForce.estimateGas.firstActivationPackWithReplace(
                freePlace,
                amount,
                forMFS
              );
            tx = await metaForce.firstActivationPackWithReplace(
              freePlace,
              amount,
              forMFS,
              {
                gasLimit: estimation.mul(3),
              }
            );
          } catch (e: any) {
            // this.callbackFn(false);
            throw e.reason;
          }
          // this.callbackFn(true);
        } else {
          let realFreePlace;
          if (freePlace == null) {
            const referrer = [referrerId];
            realFreePlace = await core.getFreePlace(referrer);
          } else {
            const referrer = [_freePlace];
            realFreePlace = await core.getFreePlace(referrer);
          }
          try {
            const estimation = await metaForce.estimateGas.firstActivationPack(
              realFreePlace,
              amount,
              forMFS
            );
            tx = await metaForce.firstActivationPack(
              realFreePlace,
              amount,
              forMFS,
              {
                gasLimit: estimation.mul(3),
              }
            );
          } catch (e: any) {
            // this.callbackFn(false);
            throw e.reason;
          }
          // this.callbackFn(true);
        }
        await tx.wait();
        return;
      }
    }
    try {
      const estimation = await metaForce.estimateGas.activationPack(
        level,
        amount,
        forMFS
      );
      console.log("metaForce.activationPack");
      tx = await metaForce.activationPack(level, amount, forMFS, {
        gasLimit: estimation.mul(3),
      });
    } catch (e: any) {
      console.log(
        "error 2222",
        JSON.stringify(e, Object.getOwnPropertyNames(e))
      );
      throw e.reason;
    }
    // this.callbackFn(true);
    await tx.wait();
  }

  public async renewPackForMFS(
    level: number,
    amount: number,
    forMFS: boolean
  ): Promise<void> {
    const { metaForce, metaPayment, metaCore, stablecoin } =
      await this.getContracts();
    const account = await this.signer.getAddress();
    const userId = await metaCore.checkRegistration(account);
    try {
      // Проверяем достаточно ли DAI на иннер балаенсе или на баланесе пользователя
      if (!forMFS) {
        const balanceOnPayment = await metaPayment.getBalance(
          stablecoin.address,
          userId
        );
        const pricePackInUSD = FIRST_PACK_PRICE_IN_USD.mul(21)
          .div(100)
          .mul(Math.pow(COEFF_INCREASE_COST_PACK_FOR_NEXT_LEVEL, level - 1));
        const needStable = pricePackInUSD.mul(amount); // .add(priceBuyingMFSInUSD);
        if (needStable.gt(balanceOnPayment)) {
          try {
            await this.approveCoin(
              metaPayment.address,
              stablecoin.address,
              needStable
            );
          } catch (e: any) {
            throw e.reason;
          }
        }
      } else {
        // TODO добавить апрув на покупку в MFS
      }

      console.log("33333");

      const estimation = await metaForce.estimateGas.renewalPackForMFS(
        level,
        amount,
        forMFS
      );
      const tx = await metaForce.renewalPackForMFS(level, amount, forMFS, {
        gasLimit: estimation.mul(3),
      });
      // this.callbackFn(true);
      await tx.wait();
    } catch (e: any) {
      console.log("error occured", e);
      // this.callbackFn(false);
      throw e.reason ?? e.message ?? e;
    }
  }

  public async renewPackForSetHMFS(
    level: number,
    amount: number,
    amountsHMFS: string[]
  ): Promise<void> {
    const { metaForce } = await this.getContracts();
    const parseAmountsHMFS = new Array(amountsHMFS.length);
    for (let i = 0; i < amountsHMFS.length; i++) {
      parseAmountsHMFS[i] = ethers.utils.parseUnits(amountsHMFS[i]!, DECIMALS);
    }
    const estimation = await metaForce.estimateGas.renewalPack(
      level,
      amount,
      parseAmountsHMFS
    );
    let tx;
    try {
      tx = await metaForce.renewalPack(level, amount, parseAmountsHMFS, {
        gasLimit: estimation.mul(3),
      });
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async createRequestMfs(usdAmount: string): Promise<number> {
    const { metaPayment, stablecoin, request } = await this.getContracts();
    const amountNumber = ethers.utils.parseUnits(usdAmount, DECIMALS);
    await this.approveCoinDeprecated(
      metaPayment.address,
      stablecoin.address,
      amountNumber
    );
    const idDistribution = await request.callStatic.createRequestMFS(
      amountNumber
    );
    let tx;
    try {
      tx = await request.createRequestMFS(amountNumber);
    } catch (e) {
      this.callbackFn(false);
      return -1;
    }
    this.callbackFn(true);
    await tx.wait();
    return idDistribution.toNumber();
  }

  public async deleteRequestMfs(requestId: number): Promise<void> {
    const { request } = await this.getContracts();
    let tx;
    try {
      tx = await request.deleteRequestMFS(requestId);
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async hold(level: number, amount: string): Promise<void> {
    const { holding, metaCore, metaPayment, hMfs } = await this.getContracts();
    const account = await this.signer.getAddress();
    const userId = await metaCore.checkRegistration(account);
    const a = ethers.utils.parseUnits(amount, DECIMALS);

    if (level > 0) {
      const hMFS = hMfs[level - 1]!;
      const balanceOnPayment = await metaPayment.getBalance(
        hMFS.address,
        userId
      );
      if (a.gt(balanceOnPayment)) {
        await this.approveCoinDeprecated(
          metaPayment.address,
          hMFS.address,
          a.sub(balanceOnPayment)
        );
      }
    }

    let tx;
    try {
      tx = await holding.hold(level, a);
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async unhold(depositId: number, amount: string): Promise<void> {
    const { holding } = await this.getContracts();
    let tx;
    try {
      tx = await holding.unhold(
        depositId,
        ethers.utils.parseUnits(amount, DECIMALS)
      );
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async withdrawHMFS(depositId: number): Promise<void> {
    const { holding } = await this.getContracts();
    let tx;
    try {
      tx = await holding.withdraw(depositId);
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async convertHMFSToMFS(level: number, amount: string): Promise<void> {
    const { holding, metaCore, metaPayment, hMfs } = await this.getContracts();
    const account = await this.signer.getAddress();
    const userId = await metaCore.checkRegistration(account);
    const a = ethers.utils.parseUnits(amount, DECIMALS);

    const hMFS = hMfs[level - 1]!;
    const balanceOnPayment = await metaPayment.getBalance(hMFS.address, userId);
    if (a.gt(balanceOnPayment)) {
      await this.approveCoinDeprecated(
        metaPayment.address,
        hMFS.address,
        a.sub(balanceOnPayment)
      );
    }

    let tx;
    try {
      tx = await holding.hMFSToMFS(level, a);
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async addToPayment(erc20: string, amount: string): Promise<void> {
    const { metaPayment } = await this.getContracts();
    const a = ethers.utils.parseUnits(amount, DECIMALS);
    await this.approveCoinDeprecated(metaPayment.address, erc20, a);
    let tx;
    try {
      tx = await metaPayment.add(erc20, a);
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait();
  }

  public async getBalanceInPayment(
    erc20: string,
    idUser?: number
  ): Promise<string> {
    const { metaPayment, metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const balance = await metaPayment.getBalance(erc20, userId);
    return ethers.utils.formatUnits(balance, DECIMALS);
  }

  public async approveCoinDeprecated(
    to: string,
    erc20: string,
    amount?: ethers.BigNumber
  ): Promise<void> {
    const coin = Erc20__factory.connect(erc20, this.signer);
    const account = await this.signer.getAddress();
    const a = amount ? amount.add(ONE) : ethers.constants.MaxUint256;
    const approvedAmount = await coin.allowance(account, to);
    if (a.gt(approvedAmount)) {
      const estimation = await coin.estimateGas.approve(to, a);
      let tx;
      try {
        tx = await coin.approve(to, a, {
          gasLimit: estimation.mul(2),
        });
      } catch (e) {
        this.callbackFn(false);
        return;
      }
      this.callbackFn(true);
      await tx.wait();
    }
  }

  public async approveCoin(
    to: string,
    erc20: string,
    amount?: ethers.BigNumber
  ): Promise<void> {
    const coin = Erc20__factory.connect(erc20, this.signer);
    const account = await this.signer.getAddress();
    const a = amount ? amount.add(ONE) : ethers.constants.MaxUint256;
    const approvedAmount = await coin.allowance(account, to);
    if (a.gt(approvedAmount)) {
      const estimation = await coin.estimateGas.approve(to, a);
      const tx = await coin.approve(to, a, {
        gasLimit: estimation.mul(2),
      });
      await tx.wait();
    }
  }

  public async calcMFSInUSD(amountMFS: string): Promise<string> {
    const { core } = await this.getContracts();
    const price = await core.calcUSDAmountForMFS(
      ethers.utils.parseUnits(amountMFS, DECIMALS)
    );
    return ethers.utils.formatUnits(price, DECIMALS);
  }

  public async calcUSDInMFS(amountUSD: string): Promise<string> {
    const { core } = await this.getContracts();
    const price = await core.calcMFSAmountForUSD(
      ethers.utils.parseUnits(amountUSD, DECIMALS)
    );
    return ethers.utils.formatUnits(price, DECIMALS);
  }

  public async checkGasEnoughForActivation(
    level: number,
    amount: number,
    forMFS: boolean,
    idUser?: number
  ): Promise<EnoughMatic> {
    let estimation = ethers.utils.parseEther("0");
    const { metaForce, metaCore, core } = await this.getContracts();
    const account = await this.signer.getAddress();
    const userId =
      idUser || (await metaCore.checkRegistration(account)).toNumber();
    if (level == 1) {
      if (!(await core.checkRegistrationInMarketing(userId))) {
        let referrerId = userId;
        while (!(await core.checkRegistrationInMarketing(referrerId))) {
          referrerId = (await metaCore.getReferrer(referrerId)).toNumber();
        }
        const { freePlace, replace } = (
          await this.getJSONFromSubSquid(
            await this.genFreePlaceQuery(referrerId)
          )
        ).data.freePlace;
        const _freePlace = Number(freePlace);
        if (replace) {
          estimation =
            await metaForce.estimateGas.firstActivationPackWithReplace(
              _freePlace,
              amount,
              forMFS
            );
        } else {
          let realFreePlace;
          if (_freePlace == null) {
            const referrer = [referrerId];
            realFreePlace = await core.getFreePlace(referrer);
          } else {
            const referrer = [_freePlace];
            realFreePlace = await core.getFreePlace(referrer);
          }
          estimation = await metaForce.estimateGas.firstActivationPack(
            realFreePlace,
            amount,
            forMFS
          );
        }
      }
    } else {
      estimation = await metaForce.estimateGas.activationPack(
        level,
        amount,
        forMFS
      );
    }
    const gazPrice = await this.provider.getGasPrice();
    const bl = await this.provider.getBalance(account);
    const needMatic = estimation.mul(gazPrice).mul(13).div(10);
    return {
      enough: bl.gte(needMatic),
      maticBalance: ethers.utils.formatUnits(bl, DECIMALS),
    };
  }

  // public async checkGasEnoughForRenew(
  //   level: number,
  //   amount: number,
  //   amountMFSForBuying: string,
  //   idUser?: number
  // ): Promise<EnoughMatic> {
  //   let estimation = ethers.utils.parseEther('0');
  //   const { metaForce, metaCore, core, metaPayment, mfs, stablecoin } =
  //     await this.getContracts();
  //   const priceFirstack = await core.getNowPriceFirstPackInMFS();
  //   const pricePackInMFS = priceFirstack.mul(
  //     Math.pow(COEFF_INCREASE_COST_PACK_FOR_NEXT_LEVEL, level - 1)
  //   );
  //   const priceRenewPackInMFS = pricePackInMFS.div(
  //     ACTIVATION_COST_RATIO_TO_RENEWAL
  //   );
  //   let parsedAmountMFS = ethers.utils.parseUnits(amountMFSForBuying, DECIMALS);
  //   if (parsedAmountMFS.gt(priceRenewPackInMFS)) {
  //     parsedAmountMFS = priceRenewPackInMFS;
  //   }
  //   const account = await this.signer.getAddress();
  //   const userId =
  //     idUser || (await metaCore.checkRegistration(account)).toNumber();
  //   const balanceOnPayment = await metaPayment.getBalance(mfs.address, userId);
  //   if (priceRenewPackInMFS.gt(balanceOnPayment)) {
  //     await this.approveCoinDeprecated(
  //       metaPayment.address,
  //       mfs.address,
  //       priceRenewPackInMFS
  //     );
  //   }
  //   const balanceOnPaymentStable = await metaPayment.getBalance(
  //     stablecoin.address,
  //     userId
  //   );
  //   const needStable = await core.calcUSDAmountForMFS(parsedAmountMFS);
  //   if (needStable.gt(balanceOnPaymentStable)) {
  //     await this.approveCoinDeprecated(
  //       metaPayment.address,
  //       stablecoin.address,
  //       needStable
  //     );
  //   }

  //   estimation = await metaForce.estimateGas.renewalPackForMFS(level, amount);
  //   const gazPrice = await this.provider.getGasPrice();
  //   const bl = await this.provider.getBalance(account);
  //   const needMatic = estimation.mul(gazPrice).mul(13).div(10);
  //   return {
  //     enough: bl.gte(needMatic),
  //     maticBalance: ethers.utils.formatUnits(bl, DECIMALS),
  //   };
  // }

  public async getRequestedMFS(
    amountUSD: string,
    idUser?: number
  ): Promise<RequestedMFS> {
    const { core, metaCore } = await this.getContracts();
    const userId =
      idUser ||
      (
        await metaCore.checkRegistration(await this.signer.getAddress())
      ).toNumber();
    const airdropPercent = parseFloat(await this.getPercentAirdrop(userId));
    const total =
      parseFloat(
        ethers.utils.formatUnits(
          ethers.utils.parseUnits(amountUSD, DECIMALS),
          DECIMALS
        )
      ) /
      parseFloat(
        ethers.utils.formatUnits(await core.getPriceMFSInUSD(), DECIMALS)
      );
    const airdrop = total * airdropPercent;
    const self = total * (1 - airdropPercent);
    return {
      total: total.toString(),
      airdrop: airdrop.toString(),
      self: self.toString(),
    };
  }

  public async getUsersCount(): Promise<string> {
    const usersCount = (
      await this.getJSONFromSubSquid(await this.genUsersCount())
    ).data.usersConnection.totalCount;
    return usersCount.toString();
  }

  public async getTotalRevenueInUSD(userId?: number): Promise<string> {
    const { core } = await this.getContracts();
    const { mfsRevenue, stableRevenue } = (
      await this.getJSONFromSubSquid(await this.genTotalRevenue(userId))
    ).data.totalRevenue;
    const priceMFSInUSD = parseFloat(
      ethers.utils.formatUnits(await core.getPriceMFSInUSD(), DECIMALS)
    );
    const floatMfsInUSD =
      parseFloat(ethers.utils.formatUnits(mfsRevenue, DECIMALS)) *
      priceMFSInUSD;
    const floatUSD = parseFloat(
      ethers.utils.formatUnits(stableRevenue, DECIMALS)
    );
    return (floatMfsInUSD + floatUSD).toFixed(2).toString();
  }

  private async getJSONFromSubSquid(argument: string): Promise<any> {
    const chainId = await this.getChainId();
    const res = await fetch(URL_SQUID[chainId], {
      method: "POST",
      body: JSON.stringify({ query: argument }),
      headers: { "Content-Type": "application/json" },
    });
    return res.json();
  }

  private async genMarketingBinaryTreeQuery(
    userId: number,
    pack: number,
    levelStart: number,
    levelEnd: number
  ): Promise<string> {
    const query = `query MarketingTree {marketingTree(userId: ${userId}, pack: ${pack},  levelStart: ${levelStart}, levelEnd: ${levelEnd}) {id, marketingReferrer, tier}}`;
    return query;
  }

  private async genMarketingLevelTreeQuery(
    userId: number,
    pack: number,
    levelStart: number,
    levelEnd: number
  ): Promise<string> {
    const query = `query MarketingTree {marketingTree(userId: ${userId}, pack: ${pack},  levelStart: ${levelStart}, levelEnd: ${levelEnd}) {id, refLevel}}`;
    return query;
  }

  private async genFreePlaceQuery(userId: number): Promise<string> {
    const query = `query FreePlace {
      freePlace(referrerId: ${userId}) {
        freePlace
        replace
      }
    }
    `;
    return query;
  }

  private async genRecentActivityQuery(
    pageSize: number,
    pageNumber: number,
    userId: number
  ): Promise<string> {
    const query = `query RecentActivity {
          users(where: {id_eq: "${userId}"}) {
        events(limit: ${pageSize}, offset: ${
      pageSize * pageNumber
    }, orderBy: [block_createdAt_DESC, id_DESC], where: {event: {isTypeOf_not_eq: "TimestampEndPack"}}) {
          contract
          block {
            createdAt
          }
          event {
            ... on MarketingReferrerChanged {
              __typename
              accountId {
                id
              }
            }
            ... on RevenueMFS {
              __typename
              activation
              amount
              level
              marketing
              from {
                id
              }
            }
            ... on RevenueStable {
              __typename
              amount
              activation
              level
              marketing
              from {
                id
              }
            }
            ... on LostMoney {
              __typename
              amount
              marketing
              from {
                id
              }
            }
            ... on MFCPackIsRenewed {
              __typename
              amount
              level
              timestampEndPack
            }
            ... on MFCPackIsActivated {
              __typename
              amount
              level
              timestampEndPack
            }
            ... on MFCTokensMFSIsBuyingInOffer {
              __typename
              amount
            }
            ... on Registration {
              __typename
              referral {
                id
              }
            }
            ... on TimestampEndPack {
              __typename
              level
              timestamp
            }
          }
        }
      }
    }
    `;
    return query;
  }

  private async genRevenueStableQuery(
    userId: number,
    timeInterval: string,
    limit: number
  ): Promise<string> {
    const query = `query RevenueStable{revenueStable(userId: ${userId}, timeInterval: "${timeInterval}", limit: ${limit}) {revenue}}`;
    return query;
  }

  private async genRevenueMFSQuery(
    userId: number,
    timeInterval: string,
    limit: number
  ): Promise<string> {
    const query = `query RevenueMFS{revenueMFS(userId: ${userId}, timeInterval: "${timeInterval}", limit: ${limit}) {revenue}}`;
    return query;
  }

  private async genLostQuery(
    userId: number,
    timeInterval: string,
    limit: number
  ) {
    const query = `query LostMoney{lostMoney(userId: ${userId}, timeInterval: "${timeInterval}", limit: ${limit}) {revenue}}`;
    return query;
  }

  private async genCountHolders() {
    const query = `query CountHolders{mfsTotalSupply {holders}}`;
    return query;
  }

  private async genUsersCount(): Promise<string> {
    const query = `query UsersCount{usersConnection(orderBy: id_ASC) {totalCount}}`;
    return query;
  }

  private async genTotalRevenue(userId?: number): Promise<string> {
    let query;
    if (userId) {
      query = `query TotalRevenue {totalRevenue(userId: ${userId}) {mfsRevenue, stableRevenue}}`;
    } else {
      query = `query TotalRevenue {totalRevenue {mfsRevenue, stableRevenue}}`;
    }
    return query;
  }

  private async genUsersPacks(userId: number): Promise<string> {
    return `query UsersPacks {packs(orderBy: level_ASC, where: {user: {id_eq: "${userId}"}}) {expiresAt}}`;
  }

  public async checkContour(): Promise<void> {
    const {
      metaForce,
      metaCore,
      core,
      holding,
      request,
      metaPayment,
      stablecoin,
      registry,
      mfs,
      energy,
    } = await this.getContracts();
    const metaPool = await registry.getMetaPool();
    const approveAmountStable = await stablecoin.allowance(
      metaPool,
      core.address
    );

    console.log(
      `metaPool approve stable for core:${approveAmountStable}(maxInt256)`
    );

    const approveAmountMFS = await mfs.allowance(metaPool, core.address);
    console.log(`metaPool approve MFS for core:${approveAmountMFS}(maxInt256)`);
    const metaContractRoleInCore = await core.hasRole(
      META_FORCE_CONTRACT_ROLE,
      metaForce.address
    );
    console.log(
      `metaForceContract has role META_FORCE_CONTRACT_ROLE in core:${metaContractRoleInCore}(true)`
    );
    const holdingontractRoleInCore = await core.hasRole(
      META_FORCE_CONTRACT_ROLE,
      holding.address
    );
    console.log(
      `holdingContract has role META_FORCE_CONTRACT_ROLE in core:${holdingontractRoleInCore}(true)`
    );
    const requestContractRoleInCore = await core.hasRole(
      META_FORCE_CONTRACT_ROLE,
      request.address
    );
    console.log(
      `requestContract has role META_FORCE_CONTRACT_ROLE in core:${requestContractRoleInCore}(true)`
    );
    const workflowStage = await core.getWorkflowStage();
    console.log(`workflow in core: ${workflowStage} (>=1)`);
    const coreMetaRole = await metaPayment.hasRole(META_ROLE, core.address);
    console.log(`core has role META_ROLE in metaPayment:${coreMetaRole}(true)`);
    const metaForceMetaRole = await metaPayment.hasRole(
      META_ROLE,
      metaForce.address
    );
    console.log(
      `metaForceContract has role META_ROLE in metaPayment:${metaForceMetaRole}(true)`
    );
    const holdingMetaRole = await metaPayment.hasRole(
      META_ROLE,
      holding.address
    );
    console.log(
      `core has role META_ROLE in metaPayment:${holdingMetaRole}(true)`
    );
    const coreMinterMFS = await mfs.hasRole(MINTER_ROLE, core.address);
    console.log(`Core has role MINTER in mfs:${coreMinterMFS}(true)`);
    const coreBurnerMFS = await mfs.hasRole(BURNER_ROLE, core.address);
    console.log(`Core has role BURNER in mfs:${coreBurnerMFS}(true)`);
    const metaForceContractMinterEnergy = await energy.hasRole(
      MINTER_ROLE,
      metaForce.address
    );
    console.log(
      `metaForceContract has role MINTER in energy:${metaForceContractMinterEnergy}(true)`
    );
    const metaForceContractBurnerEnergy = await energy.hasRole(
      BURNER_ROLE,
      metaForce.address
    );
    console.log(
      `metaForceContract has role BURNER in energy:${metaForceContractBurnerEnergy}(true)`
    );
    const eqMetaCore = metaCore.address == (await metaPayment.metaCore());
    console.log(
      `metaCore in UV and metaCore in metaPayment is equal: ${eqMetaCore} (true)`
    );
    const emissionCommited = mfs.emissionCommitted();
    console.log(`emission mfs commited: ${emissionCommited} (true)`);
  }

  /// Методы для работы с нфт чипами
  private async getJSONFromNftGraphql(query: string): Promise<any> {
    const chainId = await this.getChainId();
    const res = await fetch(NFT_GRAPHQL_ENDPOINTS[chainId], {
      method: "POST",
      body: JSON.stringify({ query: query }),
      headers: { "Content-Type": "application/json" },
    });
    return res.json();
  }

  private async getMyNftChipsQuery(user: string): Promise<string> {
    const chainId = await this.getChainId();
    return `query {
  nftTokens(
    where: {
      owner: { eq: "${user.toLocaleLowerCase()}" }
      contractAddress: {
        in: [
          "${NFT_CHIP1_ADDRESSES[chainId]}"
          "${NFT_CHIP2_ADDRESSES[chainId]}"
          "${NFT_CHIP3_ADDRESSES[chainId]}"
          "${NFT_CHIP4_ADDRESSES[chainId]}"
          "${NFT_CHIP5_ADDRESSES[chainId]}"
          "${NFT_CHIP6_ADDRESSES[chainId]}"
          "${NFT_CHIP7_ADDRESSES[chainId]}"
          "${NFT_CHIP8_ADDRESSES[chainId]}"
          "${NFT_CHIP9_ADDRESSES[chainId]}"
        ]
      }
    }
    take: 1000
  ) {
    items {
      tokenId
      contractAddress
      nftMetadata {
        name
        image
        attributes {
          traitType
          value
          maxValue
        }
      }
      nftContract {
        name
        level
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`;
  }

  public async getMyNftChips(): Promise<NftChipResponse> {
    const user = await this.signer.getAddress();
    const query = await this.getMyNftChipsQuery(user);
    const res = await this.getJSONFromNftGraphql(query);
    console.log("res", res);
    return res.data.nftTokens;
  }

  /// Методы для работы с forceswap

  public async createForceSwapOrder(amount: string) {
    const parsedAmount = ethers.utils.parseUnits(amount, DECIMALS);
    const { forceswap } = await this.getContracts();
    const estimation = await forceswap.estimateGas.createOrder(parsedAmount);
    let tx: ContractTransaction;
    try {
      tx = await forceswap.createOrder(parsedAmount, {
        gasLimit: estimation.mul(3),
      });
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait(this.DEFAULT_BLOCK_CONFIRMS);
  }

  public async changeForceSwapOrderAmount(amount: string) {
    return await this.createForceSwapOrder(amount);
  }

  public async cancelForceSwapOrder() {
    const { forceswap } = await this.getContracts();
    const estimation = await forceswap.estimateGas.cancelOrder();
    let tx: ContractTransaction;
    try {
      tx = await forceswap.cancelOrder({
        gasLimit: estimation.mul(3),
      });
    } catch (e) {
      this.callbackFn(false);
      return;
    }
    this.callbackFn(true);
    await tx.wait(this.DEFAULT_BLOCK_CONFIRMS);
  }

  public async getMyForceSwapOrderId(): Promise<string> {
    const { metaCore, forceswap } = await this.getContracts();
    const user = await this.signer.getAddress();
    const userId = (await metaCore.checkRegistration(user)).toNumber();
    const orderId = await forceswap.getOrderIdByUserId(userId);
    return orderId.toString();
  }

  public async getMyForceSwapOrder(): Promise<Web3ForceSwapOrder | null> {
    const { metaCore, forceswap } = await this.getContracts();
    const user = await this.signer.getAddress();
    const userId = (await metaCore.checkRegistration(user)).toNumber();

    const orderId = await this.getMyForceSwapOrderId();
    if (orderId == "0") {
      return null;
    }

    const order = await forceswap.getOrderById(orderId);
    let queueNumber = BigNumber.from(10000);
    try {
      queueNumber = await forceswap.getPlaceInOrder(userId);
    } catch (e) {
      console.error(e);
    }

    return {
      orderId: orderId.toString(),
      amount: order.amount.toString(),
      queueNumber: queueNumber.toString(),
    };
  }

  private getMyForceSwapOrdersHistoryQuery(userId: string) {
    return `
      query {
        forceSwapOrderChanges(
          where: { userId: { eq: "${userId}" } }
          order: { blockNumber: DESC }
        ) {
          items {
            id
            orderId
            createdAt
            txHash
            type
            amount
            amountInUsd
            chain {
              blockExplorerUrl
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      `;
  }

  public async getMyForceSwapOrdersHistory(): Promise<ForceSwapOrderChangesResponse> {
    const { metaCore } = await this.getContracts();
    const user = await this.signer.getAddress();
    const userId = (await metaCore.checkRegistration(user)).toNumber();
    const resp = await this.getJSONFromNftGraphql(
      this.getMyForceSwapOrdersHistoryQuery(userId.toString())
    );
    return resp.data.forceSwapOrderChanges;
  }

  private getForceSwapOrders(): string {
    return `
      query {
        forceSwapOrdersCount
      }
    `;
  }

  public async getForceSwapOrdersCount(): Promise<number> {
    const resp = await this.getJSONFromNftGraphql(this.getForceSwapOrders());
    return resp.data.forceSwapOrdersCount;
  }

  public getUVv2UpgradeDate(): Date {
    return UV_V2_UPGRADE_DT;
  }
}
