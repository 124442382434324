import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { coreConfig } from 'app/app-config';
import { AppComponent } from 'app/app.component';

import { ConnectWalletComponent } from './main/connect-wallet/connect-wallet.component';
import { PagesModule } from './main/pages.module';

const appRoutes: Routes = [
  {
    path: 'connect-wallet',
    component: ConnectWalletComponent
  },
  {
    path: '',
    redirectTo: '/connect-wallet',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot() ,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    PagesModule,
    //NgBootstrap
    NgbModule,
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    // App modules

  ],
  exports:[TranslateModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
  ]
})
export class AppModule {}
