import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { JsonRpcSigner } from '@ethersproject/providers';
// import { Deposit, DepositPool, Pool } from '@misha/coins-safe-sdk/dist/types';
import { environment } from 'environments/environment';

import { UserInfo,Stats,Tokens } from 'localModules/metaforcesdk/types';

import { BehaviorSubject } from 'rxjs';


export interface walletInfo {
    signer:JsonRpcSigner,
    isConnect: boolean,
    chainId: number
}


export const chainIdList ={
    polygonMainnet: 137,
    polygonTestnet:80001
}




@Injectable({ providedIn: 'root' })
export class HelpInfo  {
    expectainModalIsOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
    expectainModalStep: BehaviorSubject<number> = new BehaviorSubject(1);
    isConnect: BehaviorSubject<boolean> = new BehaviorSubject(null);
    walletAddress: BehaviorSubject<string> = new BehaviorSubject('');
    userId:BehaviorSubject<number> = new BehaviorSubject(null);
    walletInfo: BehaviorSubject<walletInfo> = new BehaviorSubject(null);
    userInfo: BehaviorSubject<UserInfo> = new BehaviorSubject(null);
    stats: BehaviorSubject<Stats> = new BehaviorSubject(null);
    tokenAddress: BehaviorSubject<Tokens> = new BehaviorSubject(null);
    
}


