export const locale = {
  lang: 'zh',
  data: {
    LEVEL_LISTS: {
      FORCE_COIN:"Force coin",
      FORCE_COIN_DESCRIPTION:"Don't miss the opportunity to be among the first",
      FORCE_COIN_BUTTON:"Get Force coin",
      CHOOSE_WHAT_MAKE_PROFIT:"Choose what you regret <br/>making a profit in:",
      CHOOSE_WHAT_MAKE_PROFIT_BUTTON:"Apply",
      LEARN_MORE: 'Learn more',
      MORE_INFORMATION_HERE:"more information here"
    }
  }
}
