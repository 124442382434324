import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { CoreTranslationService } from "@core/services/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { locale as russian } from "./i18n/ru";
import { locale as english } from "./i18n/en";
import { locale as chine } from "./i18n/zh";
import { locale as hindi } from "./i18n/hi";

import { WalletService } from "app/auth/service/wallet.service";
import { Web3Provider } from "@ethersproject/providers";
import { HelpInfo } from "app/auth/helpers";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { MyWeb3ModalService } from "app/auth/service/web3Modal.service";

declare let window: any;

@Component({
  selector: "app-connect-wallet",
  templateUrl: "./connect-wallet.component.html",
  styleUrls: ["./connect-wallet.component.scss"],
})
export class ConnectWalletComponent implements OnInit, OnDestroy {
  provider: Web3Provider;
  isConnect: boolean = false;
  reservedAddress = "";
  userId = null;
  private _unsubscribeAll = new Subject();
  language: string = "en";
  isSpare: boolean = true;

  public readonly environment = environment;

  constructor(
    public translate: TranslateService,
    private coreTranslationService: CoreTranslationService,
    private web3ModalService: MyWeb3ModalService,
    private walletService: WalletService,
    private helpInfo: HelpInfo,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    // this.isConnectStart.bind(this);
    this.coreTranslationService.translate(russian, english, chine, hindi);
    this.route.queryParams.subscribe((params) => {
      this.userId = params["id"]; // Скорее все код который был нужен для работы с резервным кошельком
      if (params["language"]) {
        this.language = params["language"];
      }
      this.translate.use(this.language);
    });
  }
  
  ngOnInit(): void {
    this.helpInfo.walletAddress
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async (x) => {
        if (x != null && x.length > 0 && this.userId) {
          // Скорее все код который был нужен для работы с резервным кошельком
          if (x != null && x.length > 0) {
            if (
              this.reservedAddress.length === 0 ||
              this.reservedAddress ===
                "0x0000000000000000000000000000000000000000"
            ) {
              // Скорее все код который был нужен для работы с резервным кошельком
              this.userId = await this.walletService.getUserId();

              this.helpInfo.userId.next(+this.userId);
              this.walletService
                .getReservedAddress(+this.userId)
                .then((address) => {
                  if (
                    address !== "0x0000000000000000000000000000000000000000"
                  ) {
                    this.reservedAddress = address.toLowerCase();
                    this.isSpare = this.reservedAddress === x.toLowerCase();
                  }
                });
            } // Скорее все код который был нужен для работы с резервным кошельком
            else {
              this.isSpare = this.reservedAddress === x.toLowerCase();
            }
          } // Скорее все код который был нужен для работы с резервным кошельком
        }
      });
    this.helpInfo.isConnect.subscribe((result) => {
      if (result !== null) {
        if (result) {
          this.isConnect = true;
        } else {
          this.isConnect = false;
        }
      }
    });
    let isConnect = localStorage.getItem("walletConnect");
    if (isConnect) {
      if (!JSON.parse(isConnect)) {
        this.reConnect();
      }
    }
  }

  async reConnect() {
    this.web3ModalService.openModal({ view: "Connect" });
  }
  back() {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
